import * as React from "react";

import "./style.css";

import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Link from "@mui/material/Link";

const Header = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AppBar position="fixed" id="header">
      <div className="container">
        <Toolbar disableGutters>
          <Box className="box1">
            <IconButton size="small" onClick={handleOpen} color="inherit">
              <MenuIcon fontSize="large" />
            </IconButton>
            <Drawer
              anchor="left"
              open={open}
              onClose={handleClose}
              PaperProps={{
                style: {
                  backgroundColor: "#212121",
                  minWidth: "200px",
                  color: "#FFF",
                },
              }}
            >
              <List>
                <Link underline="none" sx={{ color: "#FFF" }} href="#home">
                  <ListItem button divider>
                    <ListItemText primary="Home" className="drwText" />
                  </ListItem>
                </Link>
                <Link
                  underline="none"
                  sx={{ color: "#FFF" }}
                  href="#testimoni"
                >
                  <ListItem button divider>
                    <ListItemText primary="Testimonial" className="drwText" />
                  </ListItem>
                </Link>
                {/* <Link
                  underline="none"
                  sx={{ color: "#FFF" }}
                  // href=""
                >
                  <ListItem button divider>
                    <ListItemText primary="Checkout" className="drwText" />
                  </ListItem>
                </Link> */}
              </List>
            </Drawer>
          </Box>

          <Button href="#home">
            <img
              src="https://firebasestorage.googleapis.com/v0/b/lovepreneur-tayang.appspot.com/o/logo-1.png?alt=media&token=17572104-e407-4eca-aa5f-61367b4e1392"
              draggable={false}
              alt=""
              height="47px"
            />
          </Button>
          <Box sx={{ flexGrow: 1 }} />

          <Box className="box2">
            <Button className="link" href="#home">
              Home
            </Button>
            <Button
              className="link"
              href="#testimoni"
            >
              Testimonial
            </Button>
            {/* <Button
              className="link"
              // href=""
            >
              Checkout
            </Button> */}
          </Box>
          <Button
            href={props.refferal ? `https://nobar.lovepreneur.co.id/daftar/ref=${props.refferal?props.refferal:''}&produk_id=${props.prod?props.prod:''}` : 'https://nobar.lovepreneur.co.id/daftar/'}
            className="cta"
          >
            BELI TIKET
          </Button>
        </Toolbar>
      </div>
    </AppBar>
  );
};
export default Header;
